<template>
  <b-card>
    <b-table
      ref="table"
      :items="getItems"
      :fields="tableColumns"
      :current-page="pagination.currentPage"
      :per-page="pagination.perPage"
      responsive
      empty-text="No Items found"
      show-empty
    >
      <!-- Column: Actions -->
      <template #cell(actions)="{item}">
        <b-dropdown
          variant="link"
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <toggle-status
            :item="item"
            :toggle-status="toggleBudgetItemStatus"
          />

          <b-dropdown-item
            v-if="$can('update','global-BudgetItemController')"
            v-b-modal.edit-modal
            variant="outline-primary"
            @click="setItem(item)"
          >
            <feather-icon icon="EditIcon" />
            <span> Edit </span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import handleAlerts from '@/common/compositions/common/handleAlerts'
import ToggleStatus from '../../common/Table/ToggleStatus.vue'

export default {
  name: 'ItemsTable',
  components: {
    ToggleStatus,
  },
  props: {
    getItems: { type: Function, default: () => [] },
    pagination: { type: Object, default: () => {} },
  },
  data() {
    return {
      tableColumns: [
        { key: 'title' },
        { key: 'status', formatter: v => (v ? 'Yes' : 'No') },
        { key: 'actions' },
      ],
    }
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    return { successfulOperationAlert }
  },
  methods: {
    setItem(item) {
      this.$emit('update:item', { ...item })
    },
    toggleBudgetItemStatus(item) {
      this.$activities.post(`/internalops/budget/item/${item.id}/status/toggle`).then(() => {
        const operationType = item.status ? 'Deactivated' : 'Activated'
        item.status = !item.status
        this.successfulOperationAlert(`Item is ${operationType} successfully`)
      })
    },
  },
}
</script>

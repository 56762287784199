<template>
  <div>
    <!-- Add Button -->
    <div
      v-if="$can('store','global-BudgetItemController')"
      class="text-right"
    >
      <b-button
        v-b-modal.add-modal
        variant="primary"
        class="mb-1"
      >
        <span class="text-nowrap">Add Item</span>
      </b-button>
    </div>

    <!-- Search -->
    <b-card header="Search">
      <b-row>
        <b-col>
          <search
            placeholder="Name"
            :search-query.sync="searchQuery.title"
            @refresh="refreshTable"
          />
        </b-col>

        <b-col
          md="2"
          class="mt-1 mt-md-0"
        >
          <search-button @refresh="refreshTable" />
        </b-col>
      </b-row>
    </b-card>

    <!-- Table -->
    <items-table
      ref="parentTable"
      v-bind="{
        getItems,
        pagination
      }"
      :item.sync="selectedItem"
    />

    <!-- Pagination -->
    <pagination
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />

    <div>
      <!-- Add Item -->
      <b-modal
        id="add-modal"
        centered
        title="Add Item"
        hide-footer
        no-close-on-backdrop
      >
        <budget-item-form
          :budget-item="item"
          :submit="addBudgetItem"
        />
      </b-modal>

      <!-- Edit Item -->
      <b-modal
        id="edit-modal"
        centered
        title="Edit Item"
        hide-footer
        no-close-on-backdrop
      >
        <budget-item-form
          :budget-item="selectedItem"
          :submit="updateBudgetItem"
        />
      </b-modal>
    </div>
  </div>
</template>

<script>
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import Search from '@/common/components/common/Table/Search.vue'
import ItemsTable from '@/common/components/GeneralSettings/budget-items/ItemsTable.vue'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import BudgetItemForm from '@/common/components/GeneralSettings/budget-items/BudgetItemForm.vue'

import handleAlerts from '@/common/compositions/common/handleAlerts'
import paginationData from '@/common/compositions/common/paginationData'

export default {
  components: {
    ItemsTable,
    Pagination,
    Search,
    SearchButton,
    BudgetItemForm,
  },
  data() {
    return {
      item: { title: '', status: false },
      selectedItem: {},
      searchQuery: { title: '' },
    }
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    const { pagination } = paginationData()
    return {
      successfulOperationAlert,
      pagination,
    }
  },
  methods: {
    getItems() {
      return this.$activities.get('/internalops/budget/item', {
        params: {
          title: this.searchQuery.title,
          page: this.pagination.currentPage,
        },
      }).then(res => {
        const items = res.data.data
        return items || []
      }).catch(() => [])
    },
    addBudgetItem() {
      return this.$activities.post('/internalops/budget/item', this.item).then(() => {
        this.$bvModal.hide('add-modal')
        this.successfulOperationAlert('Item is added successfully')
        this.refreshTable()
      })
    },
    updateBudgetItem() {
      return this.$activities.post(`/internalops/budget/item/${this.selectedItem.id}?_method=PUT`, {
        title: this.selectedItem.title,
        status: this.selectedItem.status,
      }).then(() => {
        this.$bvModal.hide('edit-modal')
        this.successfulOperationAlert('Item is updated successfully')
        this.refreshTable()
      })
    },
    refreshTable() {
      this.$refs.parentTable.$refs.table.refresh()
    },
  },
}
</script>

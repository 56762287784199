<template>
  <validation-observer
    v-slot="{ handleSubmit }"
  >
    <b-form
      class="p-2"
      @submit.enter.prevent
    >
      <!-- Title -->
      <text-input
        id="title"
        name="Title"
        rules="required"
        :text.sync="budgetItem.title"
      />

      <b-form-checkbox
        id="status"
        v-model="budgetItem.status"
        class="mb-1"
      >
        Status
      </b-form-checkbox>
      <!-- Form Actions -->
      <submit-button
        :handle-submit="handleSubmit"
        :submit="submit"
      />
    </b-form>
  </validation-observer>
</template>

<script>
import SubmitButton from '@/common/components/common/FormInputs/SubmitButton.vue'
import TextInput from '../../common/FormInputs/TextInput.vue'

export default {
  name: 'BudgetItemForm',
  components: {
    SubmitButton,
    TextInput,
  },
  props: {
    budgetItem: { type: Object, default: () => {} },
    submit: { type: Function, default: () => {} },
  },
}
</script>

<style>

</style>
